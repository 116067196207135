import type * as Types from '@shared/types/graphqlGenerated.ts';

import * as Operations from './PublicActivePatientPromotionBanner.query';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryVariables = Types.Exact<{
  storeId: Types.Scalars['ID']['input'];
}>;


export type PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery = { viewer?: Types.Maybe<{ publicActivePatientPromotions?: Types.Maybe<{ nodes?: Types.Maybe<Array<Types.Maybe<Pick<Types.PatientPromotion, 'id' | 'patientBannerImageWebWithDateSmall2xUrl' | 'patientBannerImageWebWithDateSmallUrl' | 'patientBannerImageWebWithDateMedium2xUrl' | 'patientBannerImageWebWithDateMediumUrl' | 'patientBannerText'>>>> }> }> };



/**
 * __usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery__
 *
 * To run a query within a React component, call `usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery(baseOptions: Apollo.QueryHookOptions<PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery, PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery, PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryVariables>(Operations.PublicActivePatientPromotionBanner_UnauthenticatedSignUpSignIn_Query, options);
      }
export function usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery, PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery, PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryVariables>(Operations.PublicActivePatientPromotionBanner_UnauthenticatedSignUpSignIn_Query, options);
        }
export function usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery, PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery, PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryVariables>(Operations.PublicActivePatientPromotionBanner_UnauthenticatedSignUpSignIn_Query, options);
        }
export type PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryHookResult = ReturnType<typeof usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery>;
export type PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryLazyQueryHookResult = ReturnType<typeof usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQueryLazyQuery>;
export type PublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuerySuspenseQueryHookResult = ReturnType<typeof usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuerySuspenseQuery>;
