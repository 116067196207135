// @ts-strict-ignore
import type { ReactNode } from "react";

import { Column, Columns } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { Page } from "@unauthenticated/signupSignin/components/Page/Page";
import { PatientInfoBlock } from "@unauthenticated/signupSignin/components/PatientInfoBlock/PatientInfoBlock";
import { StoreLandingFooter } from "@unauthenticated/signupSignin/components/StoreLandingFooter/StoreLandingFooter";
import { StoreLandingPageNavBar } from "@unauthenticated/signupSignin/components/StoreLandingPageNavBar/StoreLandingPageNavBar";
import { StoreSignupInCarousel } from "@unauthenticated/signupSignin/components/StoreSignupInCarousel/StoreSignupInCarousel";

import { PromotionBanner, type PromotionBannerProps } from "./PromotionBanner/PromotionBanner";

import * as styles from "./StoreLandingLayout.styles";

interface Props extends Partial<PromotionBannerProps> {
  children: ReactNode;
  storeName: string;
  pracAvatar?: string;
  isPreview?: boolean;
  isStoreOwner?: boolean;
  storeId?: string;
}

const StoreLandingLayout = ({
  children,
  storeName,
  pracAvatar,
  isPreview,
  isStoreOwner,
  isActivePromotion,
  storeId,
}: Props) => {
  const { phoneLarge } = useBreakpoints();

  return (
    <Page>
      <StoreLandingPageNavBar isPreview={isPreview} isStoreOwner={isStoreOwner} />
      <div css={styles.layoutContainer}>
        <Columns css={styles.columnsContainer}>
          {phoneLarge.greaterThan && (
            <Column columnWidth={6} css={styles.leftColumn}>
              <PatientInfoBlock storeName={storeName} pracAvatar={pracAvatar}>
                <StoreSignupInCarousel />
                <PromotionBanner
                  isActivePromotion={isActivePromotion}
                  storeId={storeId}
                  storeName={storeName}
                />
              </PatientInfoBlock>
            </Column>
          )}
          <Column columnWidth={6} css={styles.rightColumn}>
            {children}
          </Column>
        </Columns>
      </div>
      <StoreLandingFooter />
    </Page>
  );
};

export { StoreLandingLayout };
