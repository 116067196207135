// @ts-strict-ignore
import { useTranslation } from "react-i18next";

import { AppleSSOButton } from "@shared/components/LoginOptions/AppleSSOButton/AppleSSOButton";
import { GoogleSSOButton } from "@shared/components/LoginOptions/GoogleSSOButton/GoogleSSOButton";
import { OrSection } from "@shared/components/LoginOptions/OrSection/OrSection";
import { FormWrapper } from "@unauthenticated/shared/components/FormWrapper/FormWrapper";
import { SSOErrorBox } from "@unauthenticated/shared/components/SSOErrorBox/SSOErrorBox";
import { SignInWrapper } from "@unauthenticated/shared/components/SignInWrapper/SignInWrapper";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";
import { useTreatmentPlanTerm } from "@unauthenticated/shared/hooks/useTreatmentPlanTerm/useTreatmentPlanTerm";
import { PatientSignUpForm } from "@unauthenticated/signupSignin/components/PatientSignUpForm/PatientSignUpForm";
import type { SubmitValues } from "@unauthenticated/signupSignin/components/PatientSignUpForm/PatientSignUpForm";
import { SignUpContainer } from "@unauthenticated/signupSignin/components/SignUpContainer/SignUpContainer";
import { SignUpHeader } from "@unauthenticated/signupSignin/components/SignUpHeader/SignUpHeader";
import { SignUpSubtitle } from "@unauthenticated/signupSignin/components/SignUpSubtitle/SignUpSubtitle";
import { SignUpTitle } from "@unauthenticated/signupSignin/components/SignUpTitle/SignUpTitle";
import { StoreLandingLayout } from "@unauthenticated/signupSignin/components/StoreLandingLayout/StoreLandingLayout";
import { StoreLogo } from "@unauthenticated/signupSignin/components/StoreLogo/StoreLogo";
import { StoreOffersPill } from "@unauthenticated/signupSignin/components/StoreOffersPill/StoreOffersPill";
import { usePatientSignUpMutation } from "@unauthenticated/signupSignin/data/mutations/PatientSignUp.mutation";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./PatientStoreActivationPage.styles";

interface Props {
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  pageVersion: "react_with_sso" | "react";
  ssoErrors?: string;
  storeId?: string;
  activePatientPromotion?: boolean;
}

const PatientStoreActivationPage = ({
  patientAttributes,
  additionalAttributes,
  pageVersion,
  ssoErrors,
  activePatientPromotion,
  storeId,
}: Props) => {
  const { t } = useTranslation();
  const treatmentPlanTerm = useTreatmentPlanTerm();

  const SIGN_UP_PAGE = "rx_patient_sign_up_page";

  const {
    storeLogo,
    storeName,
    firstName,
    storeSlug,
    treatmentPlanSlug,
    labRecommendationId,
    pracName,
    uuid,
  } = patientAttributes;

  const [signUp, { loading, data }] = usePatientSignUpMutation({
    onCompleted: payload => {
      if (payload.auth.patientSignUpFromRx.redirectPath) {
        window.location.assign(payload.auth.patientSignUpFromRx.redirectPath);
      }
    },
  });

  const handleSubmit = (values: SubmitValues) => {
    const { gToken, ...attributesFromForm } = values;
    const attributes = {
      ...attributesFromForm,
      treatmentPlanSlug,
      labRecommendationId,
      storeSlug,
      uuid,
      optional: additionalAttributes,
    };
    signUp({ variables: { input: { attributes, captchaToken: gToken } } });
  };

  const signupModalSubtitle = labRecommendationId
    ? t(l.patientRxAuth.SignUpWelcomeMessageLabRecommendation, {
        firstName,
        pracName,
      })
    : t(l.patientRxAuth.SignUpWelcomeMessage, {
        firstName,
        treatmentPlanTerm,
        pracName,
      });

  const renderActivationForm = () => (
    <>
      <SignUpHeader>
        <StoreLogo storeLogo={storeLogo} storeName={storeName} />
        <SignUpTitle title={storeName} />
        <SignUpSubtitle subtitle={signupModalSubtitle} />
        {!labRecommendationId && <StoreOffersPill patientAttributes={patientAttributes} />}
      </SignUpHeader>
      <SignUpContainer>
        <FormWrapper>
          <SignInWrapper>
            <SSOErrorBox ssoErrors={ssoErrors} />
            <GoogleSSOButton
              storeSlug={storeSlug}
              treatmentPlanSlug={treatmentPlanSlug}
              labRecommendationId={labRecommendationId}
              signUpPage={SIGN_UP_PAGE}
              pageVersion={pageVersion}
              uuid={uuid}
            />
            <AppleSSOButton
              css={styles.appleButton}
              storeSlug={storeSlug}
              treatmentPlanSlug={treatmentPlanSlug}
              labRecommendationId={labRecommendationId}
              signUpPage={SIGN_UP_PAGE}
              pageVersion={pageVersion}
              uuid={uuid}
            />
            <div css={styles.orWrapper}>
              <OrSection />
            </div>
            <PatientSignUpForm
              onSubmit={handleSubmit}
              isLoading={loading}
              errors={data?.auth?.patientSignUpFromRx?.errors?.fields}
              pageVersion={pageVersion}
              signUpPage={SIGN_UP_PAGE}
            />
          </SignInWrapper>
        </FormWrapper>
      </SignUpContainer>
    </>
  );

  return (
    <StoreLandingLayout
      storeName={storeName}
      storeId={storeId}
      pracAvatar={patientAttributes.pracAvatar}
      isActivePromotion={activePatientPromotion}
    >
      {renderActivationForm()}
    </StoreLandingLayout>
  );
};

export { PatientStoreActivationPage };
