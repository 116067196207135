import { PromoBanner } from "@shared/components/PromoBanner/PromoBanner";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery } from "@unauthenticated/signupSignin/data/queries/PublicActivePatientPromotionBannerQuery/PublicActivePatientPromotionBanner.generated";

import * as styles from "./PromotionBanner.styles";

interface PromotionBannerProps {
  isActivePromotion: boolean;
  storeId?: string;
  storeName: string;
}

const PromotionBanner = ({
  isActivePromotion = false,
  storeId,
  storeName,
}: PromotionBannerProps) => {
  const { desktop } = useBreakpoints();

  const { data, loading } = usePublicActivePatientPromotionBannerUnauthenticatedSignUpSignInQuery({
    variables: { storeId: storeId ?? "" },
  });

  if (!isActivePromotion || !storeId || !storeName || loading) return null;

  const patientPromotion = data?.viewer?.publicActivePatientPromotions?.nodes?.[0];

  const bannerImageWithDate = {
    small2x: patientPromotion?.patientBannerImageWebWithDateSmall2xUrl ?? "",
    small: patientPromotion?.patientBannerImageWebWithDateSmallUrl ?? "",
    medium2x: patientPromotion?.patientBannerImageWebWithDateMedium2xUrl ?? "",
    medium: patientPromotion?.patientBannerImageWebWithDateMediumUrl ?? "",
  };

  const getAltText = () => {
    const text = patientPromotion?.patientBannerText || "";

    return text.replace("{storeName}", storeName || "");
  };

  const getSize = () => {
    if (desktop.lessThan) return "small";

    return "medium";
  };

  return (
    <aside css={styles.bannerWrapper}>
      <PromoBanner
        size={getSize()}
        css={styles.banner}
        bannerImageWithDate={bannerImageWithDate}
        bannerText={getAltText() ?? ""}
      />
    </aside>
  );
};

export { PromotionBanner, type PromotionBannerProps };
