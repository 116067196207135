import { gql } from "@apollo/client";

const PublicActivePatientPromotionBanner_UnauthenticatedSignUpSignIn_Query = gql`
  query PublicActivePatientPromotionBanner_UnauthenticatedSignUpSignIn_Query($storeId: ID!) {
    viewer {
      publicActivePatientPromotions(storeId: $storeId) {
        nodes {
          id
          patientBannerImageWebWithDateSmall2xUrl
          patientBannerImageWebWithDateSmallUrl
          patientBannerImageWebWithDateMedium2xUrl
          patientBannerImageWebWithDateMediumUrl
          patientBannerText
        }
      }
    }
  }
`;

export { PublicActivePatientPromotionBanner_UnauthenticatedSignUpSignIn_Query };
