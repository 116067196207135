import type { AviarySize } from "@aviary/types";

import * as styles from "./PromoBanner.styles";

type PromoBannerSizes = Extract<AviarySize, "small" | "medium">;

interface PromoBannerProps {
  size?: PromoBannerSizes;
  bannerImageWithDate: {
    small2x: string;
    small: string;
    medium2x: string;
    medium: string;
  };
  bannerText: string;
}

const PromoBanner = ({
  size = "medium",
  bannerImageWithDate,
  bannerText,
  ...rest
}: PromoBannerProps) => {
  const imageStyles = [styles.borderRadius, styles.imageWidth[size]];

  const bannerSizes: Record<PromoBannerSizes, { image: { 1: string; 2: string }; testId: string }> =
    {
      small: {
        image: { 1: bannerImageWithDate.small, 2: bannerImageWithDate.small2x },
        testId: "promo-banner-small",
      },
      medium: {
        image: { 1: bannerImageWithDate.medium, 2: bannerImageWithDate.medium2x },
        testId: "promo-banner-medium",
      },
    };

  return (
    <img
      src={bannerSizes[size].image[1]}
      srcSet={`${bannerSizes[size].image[1]}, ${bannerSizes[size].image[2]} 2x`}
      alt={bannerText}
      data-testid={bannerSizes[size].testId}
      css={imageStyles}
      {...rest}
    />
  );
};

export { PromoBanner, type PromoBannerSizes };
